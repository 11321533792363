//@ts-ignore
import axios from "axios";

// const localhost = "http://eindiz.webplace.lv:4000/admin-api/";
const localhost =
    "https://einsteins2.webplace.lv/school-student-api/";
const adminApiBaseUrl = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL + "school-student-api/"
    : localhost;

const http = axios.create({
    baseURL: adminApiBaseUrl,
    headers: {
        Accept: "application/json",
    },
});

window["http" as any] = http as any;

export { http };
