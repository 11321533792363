import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const CountDownTimer = (props: any) => {
    const { seconds, stopTimer, setTimeIsUp, start_time, returnTime, debugTimeNow } = props;
    const [timer, setTimer] = useState(-1);
    // const [startTime, setStartTime] = useState(moment(start_time))
    const [endTime, setEndTime] = useState(moment(start_time, "YYYY-MM-DD HH:mm:ss").add(seconds, 'seconds').format("YYYY-MM-DD HH:mm:ss"));
    const [clockString, setClockString] = useState("" as string);

    const setExpired = () => {
        setTimer(0);
        return ""
    }

    useEffect(() => {
        setTimer(seconds)
    }, [])

    useEffect(() => {
        const clock = setInterval(() => {
            if (stopTimer) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                return;
            };
            if (moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) < 0) {
                clearInterval(clock);
                //console.log(moment(endTime, "HH:mm:ss").diff(moment()))
                setTimeIsUp(true);
                return;
            }
            setTimer(prev => prev - 1)
            const diff = Math.abs(moment().diff(moment(endTime, "YYYY-MM-DD HH:mm:ss"), "seconds"));
            const timeFormat = diff / 60 > 60 ? "HH:mm:ss" : "mm:ss";
            setClockString(moment.utc(diff * 1000).format(timeFormat));
            returnTime(moment.utc(diff * 1000).format(timeFormat));
        }, 1000);
        return () => { clearInterval(clock) };
    });

    useEffect(() => {
        if (timer === 0) {
            setTimeIsUp(true);
        }
    }, [timer])

    return <b style={{ color: "#CC3326", ...props.style }}>
        {
            moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment()) <= 0
                ? "00:00"
                : clockString
        }
    </b>
}